import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowsRotate,
    faCircleLeft,
    faCircleRight,
    faEye, faEyeSlash, faFilter, faFilterCircleXmark, faKey, faLock, faLockOpen,
    faMagnifyingGlass,
    faPenNib,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading";
import {useApi} from "../../hooks/useApi";
import {useModal} from "../../hooks/useModal";
import {AuthContext} from "../../contexts/Auth/AuthContext";
import VisualizarDocumento from "./VisualizarDocumento";
import {useNavigate} from "react-router-dom";
import {fitlerDocumento} from "../../types/filter_documento";
import {Tooltip} from "react-tooltip";
import './documento.css'
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal)
export default function ListarDocumento() {
    const [documentos, setDocumentos] = useState([])
    const [tiposDocumentos, setTiposDocumentos] = useState([]);
    const [filter, setFilter] = useState<fitlerDocumento>()
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    let modal = useModal();
    let auth = useContext(AuthContext)
    useEffect(() => {
        handleReadAll();
        handleTipoDocumento();
    }, [])

    async function handleTipoDocumento() {
        setLoading(true)
        let response = await api.tipo_documento.readAll();
        setTiposDocumentos(response.data);
        setLoading(false)
    }



    function handleView(e: any, documento_id: string) {
        e.preventDefault();
        navigate(`/documento/visualizar/${documento_id}`)
    }
    function handleViewProcesso(e: any, processo_id: string) {
        e.preventDefault();
        navigate(`/processo/visualizar/${processo_id}`)
    }
    function handleAlterarVisualizacao(e: any, documento_id: string) {
        e.preventDefault();
        navigate(`/documento/alterar/${documento_id}`)
    }

    const handleFilter = async (filtro: any, tipo_filtro: string) => {

        setFilter({
            direcao: tipo_filtro === "direcao" ? filtro : filter?.direcao,
            tipo_documento: tipo_filtro === "tipo_documento" ? filtro : filter?.tipo_documento,
            visualizado: tipo_filtro === "visualizado" ? filtro : filter?.visualizado,
            data_protocolo_inicio: tipo_filtro === "data_protocolo_inicio" ? filtro : filter?.data_protocolo_inicio,
            data_protocolo_final: tipo_filtro === "data_protocolo_final" ? filtro : filter?.data_protocolo_final,
            todos: tipo_filtro === "todos" ? filtro : filter?.todos,
        })
    }


    const handleReadAll = async () => {
        setLoading(true)
        let response = await api.documento.readAll();
        setDocumentos(response.data);
        setLoading(false)
    }
    const handleReadWithFilter = async (e: any) => {
        e.preventDefault()
        setLoading(true)
        let response = await api.documento.readWithFilter(filter);
        setDocumentos(response.data);
        setLoading(false)
    }

    const handleDestroyDocumento= (e:any, documento_id: any) => {
        e.preventDefault()
        MySwal.fire({
            title: "Deletar Processo nº " + documento_id ,
            text: "Tem certeza que deseja deletar o processo?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            confirmButtonColor: "#005f33",
            cancelButtonColor: "red"
        }).then(async (result) => {
            console.log(result)
            if (result.isConfirmed) {
                const response = await api.documento.delete(documento_id);
                if (response.status === 200) {
                    modal.timer("Deletar Documento", "Documento deletado com sucesso!", "success")
                    await handleReadAll()
                }

            }

        })
    }
    return (
        <>
            {loading && <Loading/>}
            <div className="p-1 mt-2 mb-5 font-verde">
                <form onSubmit={(e) => handleReadWithFilter(e)}>
                    <div className="row  mb-5">
                        <div className="col-md-1 col-sm-12">
                            <div className="form-check form-switch">
                                <input onChange={(e) => handleFilter(e.target.checked, "todos")} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Todos
                                </label>
                            </div>
                        </div>
                        <div className="col-md-1 col-sm-12">
                            <label htmlFor="filterInput" className="form-label">Direção</label>
                            <select className="form-select" aria-label="Default select example"
                                    onChange={(e) => handleFilter(e.target.value, "direcao")}>
                                <option selected>Selecione</option>
                                <option value="enviado">Enviado</option>
                                <option value="recebido">Recebido</option>
                            </select>
                        </div>

                        <div className="col-md-2 col-sm-12">
                            <label htmlFor="filterInput" className="form-label">Tipo</label>
                            <select className="form-select" aria-label="Default select example"
                                    onChange={(e) => handleFilter(e.target.value, "tipo_documento")}>
                                <option selected>Selecione</option>
                                {tiposDocumentos.map(({id, descricao}) => (
                                    <option key={id} value={id}>{descricao}</option>
                                ))}

                            </select>
                        </div>
                        <div className="col-md-1 col-sm-12">
                            <label htmlFor="filterInput" className="form-label">Visualizado</label>
                            <select className="form-select" aria-label="Default select example"
                                    onChange={(e) => handleFilter(e.target.value, "visualizado")}>
                                <option selected>Todos</option>
                                <option value="sim">Sim</option>
                                <option value="nao">Não</option>
                            </select>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <label htmlFor="filterInput" className="form-label">Protocolo/Início</label>
                            <input type="datetime-local" className="form-control" id="filterInput"
                                   placeholder="Data/Início"
                                   onChange={(e) => handleFilter(e.target.value, "data_protocolo_inicio")}/>

                        </div>
                        <div className="col-md-2 col-sm-12">
                            <label htmlFor="filterInput" className="form-label">Protocolo/Final</label>
                            <input type="datetime-local" className="form-control" id="filterInput"
                                   placeholder="Data/Fim"
                                   onChange={(e) => handleFilter(e.target.value, "data_protocolo_final")}/>

                        </div>
                        <div className="col-xs-6 col-md-1 ">
                            <label className="form-label text-white">Botão</label>
                            <div>
                                <button className="btn btn-verde btn-primary w-100" type="submit"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Filtrar"><FontAwesomeIcon icon={faFilter}/>
                                </button>
                            </div>

                        </div>
                        <div className="col-xs-6 col-md-1 1">
                            <label className="form-label text-white">Botão</label>
                            <div>
                                <button className="btn btn-verde btn-primary w-100" type="reset"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Limpar Filtro"><FontAwesomeIcon
                                    icon={faFilterCircleXmark}/>
                                </button>
                            </div>

                        </div>

                    </div>
                </form>
                <hr/>
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>C</th>
                        <th>D</th>
                        <th>Nº</th>
                        <th>Título</th>
                        <th>Processo</th>
                        <th>Resposta ao</th>
                        <th>Remetente</th>
                        <th>Destinatário</th>
                        <th className="text-center">Ações</th>
                    </tr>
                    </thead>
                    <tbody>

                    {documentos.map(({
                                         confidencial,
                                         remetente_user_id,
                                         id,
                                         processo_id,
                                         titulo,
                                         resposta_documento_id,
                                         destinatario,
                                         destinatario_user_id,
                                         numero_documento,
                                         setor_id,
                                         remetente,
                        visualizacao
                                     }) => (
                        <tr key={id}  data-tooltip-id="my-tooltip"
                            data-tooltip-content={visualizacao ? `Visualizado em ${visualizacao}`: 'Não visualizado'} >
                            <td >{confidencial === 1 ?
                                <FontAwesomeIcon color="red" icon={faLock}/> :
                                <FontAwesomeIcon icon={faLockOpen}/>}

                            </td>
                            <td>{destinatario_user_id != auth.user?.id && setor_id == null ?
                                <FontAwesomeIcon style={{color: 'red'}} icon={faCircleLeft}/> :
                                <FontAwesomeIcon style={{color: 'green'}} icon={faCircleRight}/>}

                            </td>
                            <td>{numero_documento}</td>
                            <td>{visualizacao ? '': <FontAwesomeIcon icon={faEyeSlash} style={{color: 'red'}} />}{titulo}</td>
                            <td>
                                {
                                    processo_id ?
                                    <button className={"btn  btn-outline-primary w-100"}
                                            onClick={(e) => handleViewProcesso(e, processo_id)}>{processo_id}</button>
                                    :
                                        "Sem Processo"

                                }
                            </td>
                            <td>
                                {
                                    resposta_documento_id &&
                                    <>
                                        <FontAwesomeIcon onClick={(e) => handleView(e, resposta_documento_id)}
                                                         className="p-2"
                                                         icon={faEye}/>
                                    </>

                                }

                            </td>
                            <td>{remetente}</td>
                            <td>{destinatario}</td>
                            <td className="text-center">
                                <FontAwesomeIcon onClick={(e) => handleView(e, id)} className="p-2"
                                                 icon={faEye}/>
                                {
                                    remetente_user_id == auth.user?.id &&
                                    <>
                                        <FontAwesomeIcon onClick={(e) => handleAlterarVisualizacao(e, id)}
                                                         className="p-2"
                                                         icon={faPenNib}/>
                                        <FontAwesomeIcon className="p-2"
                                                         onClick={(e) => handleDestroyDocumento(e, id)} icon={faTrash}/>
                                    </>
                                }


                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <Tooltip id="my-tooltip" className="d-none d-lg-block"/>
            </div>
        </>

    )
}